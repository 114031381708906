import request from '../util/requset2.js'

export function smsInfo(){
	return request.get("/apiData/smsInfo")
}

export function kaHaoList(params){
	return request.get("/ka/list",{
		params:params
	})
}

export function kaHaoCount(params){
	return request.get("/ka/count",{
		params:params
	})
}

export function cardInfo(params){
	return request.get("/ka/cardInfo",{
		params:params
	})
}

export function monthlyusagelog(params){
	return request.get("/ka/monthlyusagelog",{
		params:params
	})
}

export function usagelog(params){
	return request.get("/ka/usagelog",{
		params:params
	})
}

export function fetch(params){
	return request.get("/ka/fetch",{
		params:params
	})
}

export function getKaShangList(params){
	return request.get("/ka/kaShangList",{
		params:params
	})
}

export function addKaShangItem(data){
	return request.post("/ka/addKaShangItem",data)
}

export function updateKaShangItem(data){
	return request.post("/ka/updateKaShangItem",data)
}

export function deleteKaShangItem(params){
	return request.delete("/ka/deleteKaShangItem",{
		params:params
	})
}

export function getKaShangById(id){
	return request.get("/ka/getKaShangById?id="+id)
}

export function addCardItem(data){
	return request.post("/ka/addCardItem",data)
}

export function deleteCardByKaShang(id){
	return request.delete("/ka/deleteCardByKaShang?id="+id)
}
