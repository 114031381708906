import axios from 'axios'
// import { Message } from 'element-ui';
const service = axios.create({
	timeout: 30000,
	withCredentials: true,
	//baseURL:"http://127.0.0.1:8080"
	baseURL: "http://82.156.178.62:8080"
})

// service.interceptors.response.use(function(response) {
//   if(response.data.code == 415){
//     Message.error('登录过期');
//     window.location.href = "/login"
//   }else{
//     //Message.success('操作成功');
//   }
// 	return response
// }, function(error) {
//    Message.error('接口异常');
// })

// service.interceptors.request.use(
//   config => {
//     if (sessionStorage.getItem("token")) {
//       config.headers['token'] = sessionStorage.getItem("token")
//     }else{
//       var path = config.url
//       console.log(path)
//      if(path!="/user/register" && path!="/user/login" && path != "/user/sendEmail" && path != "/user/loginByEmail" && path != "loginByPassword" && path != "/question/getPagerInfo"){
//        window.location.href = "/login"
//      }
//     }
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

export default service
