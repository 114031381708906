<template>
	<div class="page">
		<div class="top-card">
			<div class="item-card">
				<div class="item-icon">
					<img src="../../assets/duanxin.png" />
				</div>
				<div class="item-content">
					<div>
						<div>本月短信数</div>
						<div style="margin-top: 20px;">{{smsNum}}</div>
					</div>

				</div>
			</div>
			<div class="item-card">
				<div class="item-icon">
					<img src="../../assets/duanxin.png" />
				</div>
				<div class="item-content">
					<div>
						<div>本月电话数</div>
						<div style="margin-top: 20px;">{{phoneCount}}</div>
					</div>

				</div>
			</div>
			<div class="item-card">
				<div class="item-icon">
					<img src="../../assets/duanxin.png" />
				</div>
				<div class="item-content">
					<div>
						<div>总计卡号量</div>
						<div style="margin-top: 20px;">{{Kacount}}</div>
					</div>

				</div>
			</div>
			<div class="item-card">
				<div class="item-icon">
					<img src="../../assets/duanxin.png" />
				</div>
				<div class="item-content">
					<div>
						<div>本月交易量</div>
						<div style="margin-top: 20px;">{{payCount}}</div>
					</div>
				</div>
			</div>
		</div>

		<div style="flex-grow: 1; box-sizing: border-box; padding: 10px; display: flex; flex-direction: column;">
			<el-tabs style="margin-top: 10px; flex-grow: 1;display: flex; flex-direction: column;" type="border-card">
				<el-tab-pane label="未导出记录">
					<xufei :state="0"></xufei>
				</el-tab-pane>
				<el-tab-pane label="已导出记录">
					<xufei :state="1"></xufei>
				</el-tab-pane>
				<el-tab-pane label="导出记录">角色管理</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import xufei from './xufei'
	import {
		smsInfo,
		kaHaoCount
	} from '../../api/api.js'
	import {
		getPhoneCount
	} from '../../api/deviceApi.js'
	import {
		getCount
	} from "../../api/pay.js"
	export default {
		components: {
			xufei
		},

		data() {
			return {
				smsNum: "加载中",
				payCount: "加载中",
				phoneCount: "加载中",
				Kacount: "加载中"
			}
		},

		mounted() {
			var that = this
			smsInfo().then(res => {
				that.smsNum = res.data.data.sendStatusStatistics.feeCount
			})

			getCount().then(res => {
				that.payCount = res.data.data
			})

			kaHaoCount().then(res => {
				that.Kacount = res.data.data
			})

			getPhoneCount().then(res => {
				that.phoneCount = res.data.data
			})

		},

		methods: {

		}
	}
</script>

<style scoped="scoped">
	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}

	.top-card {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
	}

	.item-card {
		border-radius: 4px;
		height: 110px;
		margin: 0 10px;
		flex-grow: 1;
		width: 300px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .1);
		box-sizing: border-box;
		padding: 20px;
		display: flex;
	}

	.item-icon {
		display: flex;
		align-items: center;
		height: 100%;
		width: 120px;
		text-align: center;
		justify-content: center;
	}

	.item-content {
		display: flex;
		text-align: center;
		height: 100%;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
</style>
