import request from '../util/requset.js'

export function selectList(params){
	return request.get("/device/selectList",{
		params:params
	})
}

export function deleteItem(deviceSn){
	return request.delete("/device/deleteDevice?deviceSn="+deviceSn)
}

export function getPhoneCount(){
	return request.get("/device/getPhoneCount")
}

export function getDeviceNum(clientId){
	return request.get("/device/getDeviceNum?clientId="+clientId)
}

export function getBaoJingLog(deviceSn){
	return request.get("/device/getDeviceWarnList?deviceSn="+deviceSn)
}

export function updateDeviceSubState(params){
	return request.get("/device/updateDeviceSubState",{
		params:params
	})
}

export function getDeviceLog(params){
	return request.get("/device/getDeviceLog",{
		params:params
	})
}

export function uploadDeviceDaoQiTime(params){
	return request.get("/device/uploadDeviceTimeraer",{
		params:params
	})
}

export function uploadDeviceBalance(params){
	return request.get("/device/uploadDeviceBalance",{
		params:params
	})
}

export function uploadDeviceNianXian(params){
	return request.get("/device/uploadDeviceNianXian",{
		params:params
	})
}

export function clearOpenId(data){
	return request.post("/device/clearOpenId",data)
}

export function updateVersion(params){
	return request.get("/device/updateVersion",{
		params:params
	})
}